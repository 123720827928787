@import '../../styles/variables';

.ProgressRoot {
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 99999px;
  width: 200px;
  height: 0.25rem;

  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);

  @media only screen and (max-width: $breakpoint-mobile) {
    width: 120px;
  }
}

.ProgressIndicator {
  background-color: $color-black;
  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}
