@import '../../styles/_variables';

.app {
  --main-padding: 48px;
  position: absolute;

  display: grid;
  place-content: center;

  padding: var(--main-padding);
  width: 100%;
  height: 100%;

  overflow: hidden;

  color: $color-white;

  border-color: $color-white;

  background-color: #ffd200;

  transition: 0.5s;

  @media only screen and (max-width: $breakpoint-tablet) {
    --main-padding: 32px;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    --main-padding: 16px;
  }

  .header {
    pointer-events: none;
    position: absolute;
    top: var(--main-padding);
    left: var(--main-padding);
    right: var(--main-padding);

    width: calc(100% - var(--main-padding) * 2);

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: $spacing-small;

    z-index: 10;

    font-family: $alternative-font-family;

    text-align: center;
    .instructions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: $spacing-small;
      .button {
        display: flex;
        pointer-events: all;

        padding: $spacing-x-small;

        cursor: pointer;

        font-size: $font-size-medium;

        color: $color-white;

        border: 1px solid;
        border-color: $color-white;
        border-radius: $border-radius;

        background-color: transparent;

        justify-self: center;

        @media only screen and (max-width: $breakpoint-mobile) {
          display: none;
        }
      }
    }
    .logo {
      max-width: 150px;
    }
    .branding {
      max-width: 130px;
    }
    .branding,
    .logo {
      width: 100%;

      @media only screen and (max-width: $breakpoint-mobile) {
        width: 30%;
      }
    }
    .tutorial {
      max-width: 500px;

      font-size: $font-size-basis;
      @media only screen and (max-width: $breakpoint-mobile) {
        display: none;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $spacing-medium;

    padding: 48px;
    width: 100%;
    height: 100%;

    background-color: #ffd000cd;

    transition: 0.5s;

    .title {
      font-size: 6rem;
      text-align: center;
      font-style: normal;
      font-weight: 300;
      line-height: 108%; /* 6.50881rem */
      letter-spacing: -0.12056rem;
      max-width: 71.875rem;
      margin-bottom: 1rem;

      @media only screen and (max-width: $breakpoint-tablet) {
        font-size: 5rem;
        max-width: 80%;
      }

      @media only screen and (max-width: $breakpoint-mobile) {
        font-size: 3rem;
      }
    }

    .activate {
      padding: 16px 24px;

      cursor: pointer;

      font-family: $alternative-font-family;

      color: #ffd200;
      line-height: 1em;

      border: none;
      border-radius: 12px;

      background-color: black;
    }

    .titleContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .canvasContainer {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  .visual {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;

    pointer-events: none;
    .artifact {
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 0;
      height: 100%;

      transition: width 0.75s;
      &.open {
        width: min(35vw, 400px);
        height: min(35vw, 400px);
      }
      .image {
        width: 100%;

        animation-name: idle;
        animation-duration: 10s;
        animation-iteration-count: infinite;

        animation-fill-mode: forwards;
        object-fit: contain;
      }
      @keyframes idle {
        0% {
          transform: rotate(-5deg);
        }
        50% {
          transform: rotate(5deg);
        }
        100% {
          transform: rotate(-5deg);
        }
      }
    }

    .letter {
      margin: $spacing-x-small;
      height: min(32vw, 400px);

      user-select: none;
      &:nth-child(1) {
        order: 1;
      }
      &:nth-child(2) {
        order: 2;
      }
      &:nth-child(3) {
        order: 3;
      }
      &:nth-child(4) {
        order: 4;
      }

      @media only screen and (max-width: $breakpoint-mobile) {
        margin: 4px;
      }
    }
  }

  .progressContainer {
    position: absolute;
    z-index: 5;
    top: min(calc(50% + 16vw + 2rem), calc(50% + 400px + 2rem));
    left: calc(50% - 100px);
    margin: 0 auto;

    @media only screen and (max-width: $breakpoint-mobile) {
      left: calc(50% - 60px);
    }

    // width: 100%;
    // height: 100%;

    // display: grid;
    // place-content: center;
  }

  .footer {
    pointer-events: none;

    position: absolute;
    bottom: calc(var(--main-padding) * 0.5);
    left: var(--main-padding);
    right: var(--main-padding);

    display: grid;
    justify-content: center;
    grid-template-columns: 4fr 1fr;
    gap: $spacing-large;
    padding-bottom: 0px;

    width: calc(100% - var(--main-padding) * 2);

    @media only screen and (max-width: $breakpoint-mobile) {
      display: flex;
      flex-direction: column;
      gap: 0px;
      padding-bottom: var(--main-padding);
    }

    .button {
      display: none;
      align-self: center;

      padding: $spacing-x-small;

      pointer-events: all;
      cursor: pointer;

      font-size: $font-size-medium;

      color: $color-white;

      border: 1px solid;
      border-color: $color-white;
      border-radius: $border-radius;

      background-color: transparent;

      justify-self: center;

      @media only screen and (max-width: $breakpoint-mobile) {
        display: flex;
      }
    }

    .info {
      --column-count: 4;
      --column-width: 200px;
      --gap: 24px;
      display: grid;
      flex-wrap: wrap;
      justify-content: space-between;
      grid-template-columns: repeat(
        var(--column-count),
        calc(var(--column-width) - var(--gap))
      );
      gap: var(--gap);

      max-width: 800px;

      @media only screen and (max-width: $breakpoint-tablet) {
        --column-count: 2;
        --column-width: 200px;
        --gap: 4px;
        max-width: unset;
        justify-content: start;
      }

      @media only screen and (max-width: $breakpoint-mobile) {
        --column-width: 50%;
        --gap: 0px;
      }
    }

    .link {
      @media only screen and (max-width: $breakpoint-mobile) {
        display: none;
      }

      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      width: 100%;
      a {
        pointer-events: all;
        color: inherit;
        text-decoration: none;
      }
    }

    .info,
    .link {
      .meta {
        height: 5rem;
        display: flex;
        flex-direction: column;
        .title {
          font-weight: 500;
        }
        .value {
          font-weight: 400;
        }
        .title,
        .value {
          display: flex;
          align-items: center;
          gap: $spacing-x-small;

          font-size: $font-size-basis;
        }
      }

      @media only screen and (max-width: $breakpoint-mobile) {
        .meta {
          height: 4rem;

          .title,
          .value {
            font-size: 14px;
          }
        }
      }
    }
  }
  &.light {
    color: $color-black;

    border-color: $color-black;
    .instructions {
      .button {
        color: $color-black;

        border-color: $color-black;
      }
    }
    .button {
      color: $color-black;

      border-color: $color-black;
    }
  }
  &:not(.started) {
    .overlay {
      pointer-events: all;

      opacity: 1;
    }
    .button,
    .footer,
    .tutorial,
    .info {
      pointer-events: none;

      opacity: 0;
    }
  }
  &.started {
    .overlay {
      pointer-events: none;

      opacity: 0;
    }
  }
}
