@font-face {
  font-family: 'Moulin';
  font-weight: 400;

  font-display: swap;
  src: url('./fonts/moulin-regular.eot');
  src: url('./fonts/moulin-regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/moulin-regular.woff2') format('woff2'),
    url('./fonts/moulin-regular.woff') format('woff'),
    url('./fonts/moulin-regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Moulin';
  font-weight: 300;
  src: url('./fonts/moulin-light-webfont.woff2') format('woff2'),
    url('./fonts/moulin-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Moulin';
  font-weight: 500;

  font-display: swap;
  src: url('./fonts/moulin-medium.eot');
  src: url('./fonts/moulin-medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/moulin-medium.woff2') format('woff2'),
    url('./fonts/moulin-medium.woff') format('woff'),
    url('./fonts/moulin-medium.ttf') format('ttf');
}

@font-face {
  font-family: 'Haas';

  font-display: swap;
  src: url('./fonts/neuehaasunicapro-regular.eot');
  src: url('./fonts/neuehaasunicapro-regular.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/neuehaasunicapro-regular.woff2') format('woff2'),
    url('./fonts/neuehaasunicapro-regular.woff') format('woff'),
    url('./fonts/neuehaasunicapro-regular.ttf') format('ttf');
}
