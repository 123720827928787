// Fonts -----------------------------------------------------------------------
$root-font-family: 'Moulin', serif;
$alternative-font-family: 'Haas', sans-serif;

$root-font-size: 100%;
$root-line-height: 1.32;
$root-font-weight: 400;

// Breakpoint ------------------------------------------------------------------
$breakpoint-mobile: 768px;
$breakpoint-tablet: 1080px;
$breakpoint-desktop: 1420px;

// Page sizing -----------------------------------------------------------------
$page-width: 1520px;
$content-width: 1200px;
$text-width: 720px;

// Border radius ---------------------------------------------------------------
$border-radius-small: 5px;
$border-radius: 10px;
$border-radius-full: 1000px;

// Colors ----------------------------------------------------------------------
$color-black: #000000;
$color-white: #ffffff;

$color-text: $color-black;
$color-background: $color-white;

// Effects ----------------------------------------------------------------------
$blur: blur(10px);

// Spacing ---------------------------------------------------------------------
$spacing-xx-large: 56px;
$spacing-x-large: 48px;
$spacing-large: 32px;
$spacing-medium: 24px;
$spacing-small: 16px;
$spacing-x-small: 8px;
$spacing-xx-small: 4px;

// Font-weight -----------------------------------------------------------------
$regular: 400;
$font-weight-bold: 600;

// Font-size -------------------------------------------------------------------
$font-size-xx-large: 56px;
$font-size-x-large: 40px;
$font-size-large: 32px;
$font-size-medium: 20px;
$font-size-basis: 16px;
$font-size-small: 12px;

// Text styles -------------------------------------------------------------------

@mixin h1($weight: $font-weight-bold) {
  @include hd-3xl;
  @media only screen and (max-width: $breakpoint-mobile) {
    @include hd-2xl;
  }
}
@mixin h2($weight: $font-weight-bold) {
  @include hd-2xl;
  @media only screen and (max-width: $breakpoint-mobile) {
    @include hd-xl;
  }
}
@mixin h3($weight: $font-weight-bold) {
  @include hd-xl;
  @media only screen and (max-width: $breakpoint-mobile) {
    @include hd-md;
  }
}
@mixin h4($weight: $font-weight-bold) {
  @include hd-md;
  @media only screen and (max-width: $breakpoint-mobile) {
    @include body-l;
  }
}
@mixin h5($weight: $font-weight-bold) {
  @include body-l;
  @media only screen and (max-width: $breakpoint-mobile) {
    @include body-md;
  }
}
@mixin h6($weight: $font-weight-bold) {
  @include body-md;
  @media only screen and (max-width: $breakpoint-mobile) {
    @include body-md;
  }
}
@mixin body-l($weight: $font-weight-bold) {
  @include body-md;
  @media only screen and (max-width: $breakpoint-mobile) {
    @include body-base;
  }
}
@mixin body($weight: $font-weight-bold) {
  @include body-base;
  @media only screen and (max-width: $breakpoint-mobile) {
    @include body-xs;
  }
}
@mixin body-s($weight: $font-weight-bold) {
  @include body-xs;
  @media only screen and (max-width: $breakpoint-mobile) {
    @include body-xs;
  }
}

@mixin hd-3xl($weight: $font-weight-bold) {
  font-size: $font-size-xx-large;
  font-weight: $weight;

  line-height: 130%;
}
@mixin hd-2xl($weight: $font-weight-bold) {
  font-size: $font-size-x-large;
  font-weight: $weight;

  line-height: 130%;
}
@mixin hd-xl($weight: $font-weight-bold) {
  font-size: $font-size-large;
  font-weight: $weight;

  line-height: 130%;
}
@mixin hd-md($weight: $font-weight-bold) {
  font-size: $font-size-medium;
  font-weight: $weight;

  line-height: 120%;
}

@mixin body-md($weight: $regular) {
  font-size: $font-size-medium;
  font-weight: $weight;

  line-height: 136%;
}
@mixin body-base($weight: $regular) {
  font-size: $font-size-basis;
  font-weight: $weight;

  line-height: 136%;
}
@mixin body-xs($weight: $regular) {
  font-size: $font-size-small;
  font-weight: $weight;

  line-height: 136%;
}

@mixin caption-md($weight: $regular) {
  font-size: $font-size-medium;
  font-weight: $weight;

  line-height: 112%;
}
@mixin caption-base($weight: $regular) {
  font-size: $font-size-basis;
  font-weight: $weight;

  line-height: 112%;
}
@mixin caption-xs($weight: $regular) {
  font-size: $font-size-small;
  font-weight: $weight;

  line-height: 112%;
}
